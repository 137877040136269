<template>
  <div>
    <NuxtLink
      v-if="typeof item === 'object'"
      :to="{
        name: indexStore.currentPaperSlug ? 'paper_qa' : 'qa',
        params: {
          id: item.RecordId,
          paper: indexStore.currentPaperSlug,
        },
      }"
      class="flex"
    >
      <div
        v-if="placement === 'side'"
        class="w-12 h-12 rounded-full shrink-0 grow-0 mr-3 bg-center bg-cover"
        :style="{
          backgroundImage:
            'url(' +
            config.public.site.legacydomain +
            '/images/person/' +
            (parseInt(item.Answered) && item.Aperson && item.Aperson[0]
              ? item.Aperson[0].RecordId
              : item.QPersons && item.QPersons[0]
              ? item.QPersons[0].RecordId
              : '') +
            '/' +
            (parseInt(item.Answered) && item.Aperson && item.Aperson[0]
              ? item.Aperson[0].Image
              : item.QPersons && item.QPersons[0]
              ? item.QPersons[0].Image
              : '') +
            ')',
        }"
      ></div>
      <div>
        <div
          v-if="item.Type === 4 && item.QPersons && item.QPersons[0]"
          class="mb-1 text-black"
          :class="
            placement === 'side'
              ? 'list-label'
              : 'list-title-s !font-light flex items-center'
          "
        >
          Svar til
          <span
            v-if="placement === 'main'"
            class="inline-block w-8 h-8 rounded-full shrink-0 grow-0 ml-2 mr-2 bg-center bg-cover"
            :style="{
              backgroundImage:
                'url(' +
                config.public.site.legacydomain +
                '/images/person/' +
                (parseInt(item.Answered) && item.Aperson && item.Aperson[0]
                  ? item.Aperson[0].RecordId
                  : item.QPersons && item.QPersons[0]
                  ? item.QPersons[0].RecordId
                  : '') +
                '/' +
                (parseInt(item.Answered) && item.Aperson && item.Aperson[0]
                  ? item.Aperson[0].Image
                  : item.QPersons && item.QPersons[0]
                  ? item.QPersons[0].Image
                  : '') +
                ')',
            }"
          />
          <span class="text-red">{{ item.QPersons[0].Name }}</span
          >:
        </div>
        <div
          v-if="
            item.Type !== 4 &&
            parseInt(item.Answered) &&
            item.Aperson &&
            item.Aperson[0]
          "
          class="mb-1 text-black"
          :class="
            placement === 'side'
              ? 'list-label'
              : 'list-title-s !font-light flex items-center'
          "
        >
          <span
            v-if="placement === 'main'"
            class="inline-block w-8 h-8 rounded-full shrink-0 grow-0 mr-2 bg-center bg-cover"
            :style="{
              backgroundImage:
                'url(' +
                config.public.site.legacydomain +
                '/images/person/' +
                (parseInt(item.Answered) && item.Aperson && item.Aperson[0]
                  ? item.Aperson[0].RecordId
                  : item.QPersons && item.QPersons[0]
                  ? item.QPersons[0].RecordId
                  : '') +
                '/' +
                (parseInt(item.Answered) && item.Aperson && item.Aperson[0]
                  ? item.Aperson[0].Image
                  : item.QPersons && item.QPersons[0]
                  ? item.QPersons[0].Image
                  : '') +
                ')',
            }"
          />
          <span class="text-red">{{ item.Aperson[0].Name }}</span
          >&nbsp;{{ $t('Answers') }}:
        </div>
        <div
          v-else-if="item.Type !== 4 && item.QPersons && item.QPersons[0]"
          class="mb-1 text-black"
          :class="
            placement === 'side'
              ? 'list-label'
              : 'list-title-s !font-light flex items-center'
          "
        >
          <span
            v-if="placement === 'main'"
            class="inline-block w-8 h-8 rounded-full shrink-0 grow-0 mr-2 bg-center bg-cover"
            :style="{
              backgroundImage:
                'url(' +
                config.public.site.legacydomain +
                '/images/person/' +
                (parseInt(item.Answered) && item.Aperson && item.Aperson[0]
                  ? item.Aperson[0].RecordId
                  : item.QPersons && item.QPersons[0]
                  ? item.QPersons[0].RecordId
                  : '') +
                '/' +
                (parseInt(item.Answered) && item.Aperson && item.Aperson[0]
                  ? item.Aperson[0].Image
                  : item.QPersons && item.QPersons[0]
                  ? item.QPersons[0].Image
                  : '') +
                ')',
            }"
          />
          <span class="text-red">{{ item.QPersons[0].Name }}</span
          >&nbsp;{{ $t('Asks') }}:
        </div>
        <div
          class="text-black"
          :class="placement === 'side' ? 'list-title-xxxs' : 'list-title-m'"
        >
          {{ item.Headline }}
        </div>
        <div
          v-if="placement === 'main'"
          class="mt-3 article-label !font-medium opacity-50 flex items-center"
        >
          {{ formatDate(getTimeAsDate, false) }}
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  item: {
    type: [Object, Number],
    default: null,
  },
  index: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: '1',
  },
  placement: {
    type: String,
    default: 'side',
  },
})

const { item, index, type, placement } = toRefs(props)

const route = useRoute()
const indexStore = useIndexStore()
const config = useRuntimeConfig()

const currentPage = () => {
  let page = null
  switch (route.name) {
    case 'news':
    case 'paper_news':
    case 'debate':
    case 'paper_debate':
    case 'job-names':
    case 'paper_job-names':
      page = route.path
      break
    case 'searchwords':
    case 'paper_searchwords':
      page = route.path
      break
  }
  if (page) {
    return page
  } else {
    return currentPaper
  }
}

const currentPaper = () => {
  if (
    indexStore.currentPaper &&
    parseInt(indexStore.currentPaper.RecordId) !== 1
  ) {
    return `/${indexStore.currentPaper?.Url}`
  } else {
    return ''
  }
}

// API returns Time in format dd-mm-yyyy hh:mm, however
// Date constructor expects mm-dd-yyyy hh:mm
const getTimeAsDate = computed(() => {
  return new Date(
    item.value.Time.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2-$1-$3')
  )
})
</script>
